.section {
    padding: 1rem 0 2rem 0;
}

.section-content {
    max-width: 1500px;
    margin: 0 auto 0 auto;
}

.section h1 {
    text-align: center;
    animation: fadeIn 2s ease;
    color: #ffffff;
}
