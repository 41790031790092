.sw-main {
    display: flex;
    border-radius: 5px;
    position: relative;
}

.sw-image {
    display: flex;
    margin: 0 auto 0 auto;
}

.sw-image>img {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    object-fit: fill;
    margin: auto 0 auto 0;
}

.sw-blurred {
    opacity: 0.2;
}

.sw-arrow-left {
    display: flex;
    position: absolute;
    top: calc(50% - 2.5rem);
    height: 5rem;
}

.sw-arrow-right {
    display: flex;
    position: absolute;
    top: calc(50% - 2.5rem);
    right: 0;
    height: 5rem;
}

.sw-arrow-left>button,
.sw-arrow-right>button {
    background: rgba(0, 0, 0, 0.2);
    padding: 0 0 0 0;
    border: none;
    border-radius: 5px;
    color: white;
}

.sw-arrow-left>button:hover,
.sw-arrow-right>button:hover {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.1s ease;
    border-radius: 5px;
}