.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
}

.title {
    font-size: 3rem;
    margin: 0 0 1rem 0;
}

.subtitle  {
    font-size: 1.5rem;
    margin: 0 0 1.5rem 0;
}

.me {
    width: 270px;
    height: 270px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0px 0px 6px 2px rgba(0,0,0, 0.5);
}

@media only screen and (max-width: 800px) {
    .home {
        min-height: 70vh;
    }
}