.project {
    display: flex;
    flex-direction: row;
    padding: 3rem 2rem 3rem 2rem;
    animation: fadeIn 1s;
}

.blurred {
    color: #696b68;
}

.project-details,
.project-details-sm {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    margin: 0 1rem 0 1rem;
}

.project-details-sm {
    max-width: 600px;
}

.project-skills {
    display: flex;
    flex-wrap: wrap;
}

.project-image {
    display: flex;
    flex-basis: 100%;
    overflow: auto;
}

.project-details h3,
.project-details-sm h3 {
    font-size: 2.3rem;
    margin: 0 0 0 0;
}

.project-small-desc,
.project-desc {
    margin: 0 0 10px 5px;
}

@media only screen and (max-width: 1200px) {
    .project {
        flex-wrap: wrap;
    }

    .project-details-sm {
        max-width: inherit;
    }

    .project-image {
        margin: 0 1rem 0 calc(1rem + 5px);
    }
}

@media only screen and (max-width: 800px) {
    .project-details {
        text-align: center;
        align-items: center;
    }

    .project-details-sm {
        text-align: center;
        align-items: center;
    }

    .project-skills {
        justify-content: center;
    }
}