.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.skills-help {
    color: #dbdbdb;
    font-size: 0.9rem;
    font-style: italic;
    text-align: center;
    margin: 1rem 0 0 0;
}

.skills-ctn {
    margin: 1rem 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}