.control-group {
    padding: 8px;
    border: solid 1px #b3b3b3;
    margin: 2rem auto 2rem auto;
}

.control-group-title {
    position: relative;
    top: -21px;
    max-width: 150px;
    background: #252424;
    left: calc(50% - 75px);
    text-align: center;
    color: #ffffff;
    font-weight: 600;
}