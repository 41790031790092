.divider {
    height: 1px;
    background-color: #ffffff;
    width: 90%;
    margin: 0 auto 0 auto;
}

.divider-transition {
    animation: slidein 2s ease;
}

@keyframes slidein {
    0% {
        max-width: 0%;
    }

    100% {
        max-width: 90%;
    }
}