.skill-badge {
    border: 1px solid #000000;
    padding: 6px 10px 6px 10px;
    margin: 0.5rem;
    min-width: 80px;
    text-align: center;
    border-radius: 8px;
    user-select: none;
    background-color: #818181;
    color: #000000;
    font-weight: 600;
    transition: all 0.2s ease-in;
}

.skill-badge.selected {
    background: #55a369;
}

.sb-blurred {
    background-color: #545554;
}

.sb-small {
    padding: 2px 2px 2px 2px;
    font-size: 13px;
    min-width: 65px;
    margin: 0.2rem;
}

.skill-badge-clickable {
    cursor: pointer;
    background-color: #c5c5c5;
}

.skill-badge-clickable:hover {
    transform: scale(1.05);
}