body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #252424;
    color: #e6e6e6;
    font-size: 16px;
}

@media only screen and (max-width: 800px) {
    html {
        font-size: 13px;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}